import React from 'react'
import * as HomepageBackgroundStyle from './css/homepageBackground.module.css'
 import movie from '../assets/home_Intro.mp4'
  // import movie from '../assets/winter.mp4'
//  import movie from '../assets/home3.mp4'
//  import movie from '../assets/1234.mp4'
//  import movie from '../assets/123.mp4'
  // import movie from '../assets/winterMovie.mp4'
  // import movie from '../assets/winter1.mp4'
// import movie from '../assets/background_video_original.mp4'
import { faDoorOpen,faCalendarWeek } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
const HomepageBackground = () => (
  <>
    <div className={`container-fluid p-0 ${HomepageBackgroundStyle.container}`}>
      <video loop muted autoPlay playsInline>
        <source src={movie} type='video/mp4' />
        {/* <source src='https://marsta.ellstroms.se/wp-content/uploads/2020/12/host_korning_Ellstroms_komp.mp4' type='video/webm' /> */}
      </video>
      <div className={` ${HomepageBackgroundStyle.overlay}`}>
      {/* <span className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.welcomeText}`}> */}
        <span className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.welcomeText}`}>
          Välkommen till  <br /> Danderyds Trafikskola
          {/* <br /> */}
          <br />

            {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter}`}>Semesterstängt vecka 29 och 30</p> */}
            <br></br>
              {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempText}`}>Hösterbjudande!</p> */}
              {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter}`}>JUST NU!</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter}`}>Vintererbjudande</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter2}`}>upp till 20 % rabatt</p> */}
              {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter}`}>20 % rabatt</p> */}
              {/* <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextWinter2}`}>på körlektioner</p> */}
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempText}`}>JUST NU!</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextSummer}`}>Vår &</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextSummer}`}>Sommarerbjudande</p>
              <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextSummer}`}>upp till 15 % rabatt</p>
               <p className={` animate__animated animate__fadeInUp ${HomepageBackgroundStyle.tempTextSummer}`}>på körlektioner</p>
              <br />
          <Link to='/e-handel' className='btn btn-danger'><FontAwesomeIcon icon={faCalendarWeek} size='1x' style={{ color: 'white' }} /> Boka någon av våra utbildningar här</Link>
           <br />
          <button
            title='Fungerar endast vid trafikskolans öppettider'
            className={`btn btn-danger animate__animated animate__fadeInUp  
            ${HomepageBackgroundStyle.welcomeTextButton}`}>
            <FontAwesomeIcon icon={faDoorOpen} size='1x' style={{ color: 'white' }} />   Portkod: 7452
          </button>
        </span>
      </div>
    </div>
  </>
)
export default HomepageBackground
