// extracted by mini-css-extract-plugin
export var button2 = "homepageBackground-module--button2--01aec";
export var container = "homepageBackground-module--container--5b730";
export var overlay = "homepageBackground-module--overlay--1ea6b";
export var tempText = "homepageBackground-module--temp-Text--db33d";
export var tempTextSummer = "homepageBackground-module--temp-Text-summer--fa451";
export var tempTextSummersub = "homepageBackground-module--temp-Text-summersub--1fe95";
export var tempTextWinter = "homepageBackground-module--temp-Text-winter--39f81";
export var tempTextWinter2 = "homepageBackground-module--temp-Text-winter2--33512";
export var welcomeText = "homepageBackground-module--welcome-Text--8de1f";
export var welcomeTextButton = "homepageBackground-module--welcome-TextButton--409d3";